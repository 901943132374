#section-schedule{
  width: 1280px;margin:30px auto;
  .box-schedule{
    display: flex;
  }
  .box{width: 50%;box-sizing: border-box;padding: 30px;}
}
@media screen and (max-width: 767px) {
  #section-schedule{
    width: 100%;padding: 0 2% 0;
    box-sizing:border-box;
    .box-schedule{
      display: block;
    }
/*TODOs aa*/
    .box{width: 100%;box-sizing: border-box;padding: 10px;}

  }
}