@import "../../style/_variable.scss";

#section-ice{
  width: 100%;
  background: url(../../images/bg-happy.png) no-repeat;
  background-position: center bottom;
  height: 1000px;
  padding-top: 0;
  padding-bottom: 100px;
  background-size: 100% auto;
  position: relative;
  h2{
    color: $green;
    font-size: 62px;
    font-weight: bold;
    position: absolute;
    top:40px;
    right: 200px;
    text-transform: uppercase;
    &:after{
      content: '';
      background: url(../../images/shape-lb.png) no-repeat;
      background-size: 35px 35px;
      position: absolute;
      left: -20px;bottom: -20px;
      width: 35px;
      height: 35px;
    }
    &:before{
      content: '';
      background: url(../../images/shape-tr.png) no-repeat;
      background-size: 35px 35px;
      position: relative;
      right: -450px;
      top: 20px;
      width: 35px;
      height: 35px;
      display: block;      
    }
  }
  h3{
    font-size: 36px;color: $black;
    margin: 80px 0 20px 50px;
    position: absolute;
    right: 500px;
    top:180px;
    color:$green;
    text-transform: uppercase;
  }
  .lead{
    font-size: 24px;
    color: $green;
    width: 400px;
    margin: 0 0 0 50px;
    padding-bottom: 20px;
    line-height: 1.4;
    border-bottom: 2px solid $green;
    position: absolute;
    right: 300px;
    top:330px;
  } 
 .bottom{
  margin: 30px 0 0 50px;
  color: $black;
  font-size: 24px;
  line-height: 1.4;;
  position: absolute;
  right: 470px;
  top:440px;
  color: $green;
  }
  .box-price{
    position: absolute;
    top:60px;
    left:150px;
    width: 300px;
    color: $green;
    &::after{
      content: '';
      display: block;
      background: url(../../images/shape-lb.png) no-repeat;
      background-size: 35px 35px;
      width: 35px;
      height: 35px;
      position: absolute;
      left: -20px;
      bottom: -20px;      
    }
  
    .date{
      font-size: 24px;
      color: $green;
      .hs{
        font-size: 20px;
      }
    }
    .price{position: absolute;
      right: 80px;
      font-size: 24px;
      top:-10px
    }
  }
}