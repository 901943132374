@import "../../style/_variable.scss";

.section-autumn{
  padding: 0 0 40px;
  overflow: hidden;

h2{
  font-size: 56px;
  color: $purple;
  font-weight: bold;
  margin: 40px 0 0 50px;
  position: relative;
  left: 60px;
  &:after{
    content: '';
    background: url(../../images/shape-lb.png) no-repeat;
    background-size: 35px 35px;
    position: absolute;
    left: -20px;bottom: -20px;
    width: 35px;
    height: 35px;
  }
  &:before{
    content: '';
    background: url(../../images/shape-tr.png) no-repeat;
    background-size: 35px 35px;
    position: relative;
    right: -400px;
    top: 20px;
    width: 35px;
    height: 35px;
    display: block;      
  }
}
.wrap-photo{
  display: flex; 
  width: 1200px;margin:50px auto;
  justify-content:center;
  .box-photo{
    margin: 0 30px 0;;
  }
}

.box-btn{
  width: 200px;margin: 0 auto;;
}
}

@media screen and (max-width: 767px) {
  .section-autumn{
    padding: 0 0 40px;
    overflow: hidden;
  
    h2{
      font-size: 28px;
      color: $purple;
      font-weight: bold;
      margin: 20px 0 0 20px;
      position: relative;
      left: 20px;
      &:after{
        content: '';
        background: url(../../images/shape-lb.png) no-repeat;
        background-size: 35px 35px;
        position: absolute;
        left: -20px;bottom: -20px;
        width: 35px;
        height: 35px;
      }
      &:before{
        content: '';
        background: url(../../images/shape-tr.png) no-repeat;
        background-size: 35px 35px;
        position: relative;
        right: -200px;
        top: 20px;
        width: 35px;
        height: 35px;
        display: block;      
      }
    }
    .wrap-pgoto{
      display: flex; 
      width: 100%;margin:50px auto;
      padding: 10px 0 30px;
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;
      .box-photo{
        margin: 0 30px 0;;
      }
    }
    .box-btn{
      width: 200px;margin: 0 auto;;
    }
  }  
}