@import "../../style/_variable.scss";
.btn{
  a{
    text-align: center;
    background: #fff;
    color: $black;
    display: block;  
    padding: 5px 40px;
    border-radius: 20px;
    transition: 0.4s;
    &:hover{
      background-color: $black;
      color: #fff;
    }
    &.purple{
      background: #564b67;
      color: #fff;
      display: block;  
      padding: 5px 40px;
      transition: 0.4s;    
      &:hover{
        background-color: $black;
        color: #fff;
      }
    }
    &.green{
      background: $green;
      color: #fff;
      display: block;  
      padding: 5px 40px;
      transition: 0.4s;
      &:hover{
        background-color: $black;
        color: #fff;
      }
    }
  }
}