@import "../../style/_variable.scss";
section.schedule_box {
  width: 100%;
  box-shadow: 3px 1px 10px #ccc;
  border-radius: 10px;
  padding: 50px;
  transition: 0.3s;
  cursor: pointer;
  &:hover{
    box-shadow: 3px 1px 10px #666;
  }
  h3 {
    font-size: 32px;
    color: $black;
    margin-bottom: 15px;
	}
	div.wrap {position: relative;border-bottom:2px solid $black;padding: 0 0 30px;margin: 0 0 30px;
		ul {
			li {
        font-size: 20px;
        line-height: 1.6;
        color: $black;
			}
		}
		div.image {position: absolute;top:0;right:0px;
			img {
			}
		}
		p {
		}
	}
  .box-bottom{
   .description{font-size: 24px;} 
   .hour{font-size: 24px;}
   .circle{font-size: 24px;display: inline;}
   .number{display: inline;font-size: 18px;margin-left:20px;}
  }
}

@media screen and (max-width: 767px) {
  section.schedule_box {
    width: 100%;
    box-shadow: 3px 1px 10px #ccc;
    border-radius: 10px;
    padding: 20px;
    transition: 0.3s;
    cursor: pointer;
    &:hover{
      box-shadow: 3px 1px 10px #666;
    }
    h3 {
      font-size: 32px;
      color: $black;
      margin-bottom: 15px;
    }
    div.wrap {position: relative;border-bottom:2px solid $black;padding: 0 0 10px;margin: 0 0 30px;
      ul {
        li {
          font-size: 20px;
          line-height: 1.6;
          color: $black;
        }
      }
      div.image {position: absolute;top:-50px;right:-20px;
        img {
        }
      }
      p {
      }
    }
    .box-bottom{
     .description{font-size: 24px;} 
     .hour{font-size: 24px;}
     .circle{font-size: 16px;display: inline;}
     .number{display: inline;font-size: 18px;margin-left:20px;}
    }
  }
  
}