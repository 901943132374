.section-banner_national{
  width: 100%;
  padding-top: 40%;
  background: url('../../images/banner_p.png') no-repeat;
  background-size: cover;
  height: 500px;
  position: relative;
  .btn{
    position: absolute;
    right: 150px;
    bottom: 120px;
  }
}

@media screen and (max-width: 767px) {
  #banner-national{
    width: 100%;;
  }
  .section-banner_national{
    width: 100%;    
    background: url('../../images/banner_p.png') no-repeat;
    background-size: cover;
    height: 220px;
    position: relative;
    .btn{
      position: absolute;
      right: 0;
      left: 0;
      margin: 0 30px 0;
      bottom: 5px;
    }
  }
}