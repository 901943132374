@import "../../style/_variable.scss";
@media screen and (min-width: 768px) {
  .global-footer {
    position: relative;
    height: 100px;
    overflow: hidden;
    .logo {
      position: absolute;
      left: 90px;
      top: 30px;
    }
    nav {
      ul {
        display: flex;
        position: absolute;
        left: 300px;
        top: 45px;
        width: 400px;
        li {
          list-style: none;
          font-size: 20px;
          margin: 0 40px;
          a {
            color: $black;
            transition: 0.5s;
            border-bottom: 0px solid;
            padding-bottom: 5px;
            position: relative;
            &:after {
              content: "";
              width: 0;
              height: 2px;
              position: absolute;
              top: 30px;
              left: 0px;
              background: $black;
              transition: 0.3s;
            }
            &:hover {
              &:after {
                background: $black;
                content: "";
                width: 100%;
              }
            }
          }
        }
      }
    }
    .ds {
      text-align: center;
      font-size: 16px;
      width: 100%;
      padding: 10px 0;
      a {
        color: #3198c9;
        text-decoration: underline;
      }
    }
    .copy {
      text-align: right;
    }
  }
}
@media screen and (max-width: 767px) {
  .global-footer {
    position: relative;
    height: auto;
    overflow: auto;
    padding-bottom: 50px;
    display: block;
    .logo {
      display: block;
      position: relative;
      left: auto;
      top: auto;
      margin: 30px auto 30px;
    }
    nav {
      ul {
        position: relative;
        display: block;
        left: auto;
        top: auto;
        left: auto;
        width: 100%;
        li {
          list-style: none;
          font-size: 20px;
          margin: 0 0;
          a {
            display: block;
            padding: 10px 20px;
            color: $black;
            transition: 0.5s;
            padding-bottom: 5px;
            position: relative;
            border-bottom: 1px dotted $black;
            width: 100%;
          }
        }
      }
    }
    .ds {
      text-align: center;
      font-size: 16px;
      width: 100%;
      padding: 20px 0 20px;
      a {
        color: #3198c9;
        text-decoration: underline;
      }
    }
    .copy {
      text-align: right;
    }
  }
}
