.section-banner-hiking{
  width: 100%;
  background: url(../../images/banner-hiking.png) no-repeat;
  height: 500px;
  background-size: 100%;
  padding-top:40%;
  position: relative;
  .btn{
    position: absolute;
    bottom: 150px;
    left: 120px;
  }
}

@media screen and (max-width: 767px) {
  .section-banner-hiking{
    width: 100%;
    background: url(../../images/banner-hiking.png) no-repeat;
    height: 150px;
    background-size: 100%;
    padding-top:0%;
    position: relative;
    .btn{
      position: absolute;
      bottom: 10px;
      left: 120px;
    }
  }  
}