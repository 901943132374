@import "../../style/_variable.scss";
.global-header{display: flex;position: relative;height: 100px;overflow: hidden;
  .logo{position: absolute;left: 90px;top:30px;}
  nav{
    ul{display: flex;position: absolute;left: 300px;top:45px;width: 400px;
      li{list-style: none;font-size: 20px;margin: 0 40px;
        a{
          color: $black;
          transition: 0.5s;
          border-bottom: 0px solid;
          padding-bottom: 5px;
          position: relative;
          &:after{
            content: '';
            width: 0;
            height: 2px;
            position: absolute;
            top:30px;
            left: 0px;
            background: $black;
            transition: 0.3s;
          }          
          &:hover{
            &:after{
            background: $black;
            content: '';
            width: 100%;
            }
          }
          
        }
      }
    }
  }
  form{position: absolute;top:20px;right: 80px;}
  .search{
    box-shadow: 0 4px 6px #999 inset;border-radius: 20px;
    padding: 5px;
  }
  .submit{font-size: 0px;background: url(../../images/icon-grass.png) no-repeat;background-size: 20px 20px;width: 24px;height: 24px;position: absolute;right: 10px;top: 5px;}
}

@media screen and (max-width: 767px) {
  .global-header{display: flex;position: relative;height: 100px;overflow: hidden;
    .logo{position: absolute;left: 20px;top:30px;}
    nav{display: none;
      ul{position: relative;
        li{list-style: none;font-size: 20px;margin: 0 0;
          a{
            color: $black;
            transition: 0.5s;
            border-bottom: 0px solid;
            padding-bottom: 5px;
            position: relative;
            &:after{
              content: '';
              width: 0;
              height: 2px;
              position: absolute;
              top:30px;
              left: 0px;
              background: $black;
              transition: 0.3s;
            }          
            &:hover{
              &:after{
              background: $black;
              content: '';
              width: 100%;
              }
            }
            
          }
        }
      }
    }
    form{position: absolute;top:10px;right: 40px;}
    .search{
      box-shadow: 0 4px 6px #999 inset;border-radius: 20px;
      padding: 5px;
    }
    .submit{font-size: 0px;background: url(../../images/icon-grass.png) no-repeat;background-size: 20px 20px;width: 24px;height: 24px;position: absolute;right: 10px;top: 5px;}
  }
  
}