@import "../../style/_variable.scss";

.box-photo{
  width: 300px;;
  .image{
    img{transition: 0.3s;height: 300px;overflow: hidden;
      cursor: pointer;
      margin-bottom: 30px;
    &:hover{transform: scale(1.05,1.05);}}
  }
  h3{color: $black;font-size: 32px;font-weight: bold;margin: 0 0 20px;text-transform: uppercase;}
  .text{font-size: 18px;color: $black;padding: 0 0 30px;height: 4em;box-sizing: border-box;}
  .location{font-size: 22px;color: $black;border-top: 1px solid $black;padding-top: 20px;}
}
@media screen and (max-width: 767px) {
  .box-photo{
    width: 300px;
    margin: 0 0 0 40px;
    .image{
      img{transition: 0.3s;height: 300px;overflow: hidden;
        cursor: pointer;
        margin-bottom: 30px;
      &:hover{transform: scale(1.05,1.05);}}
    }
    h3{color: $black;font-size: 32px;font-weight: bold;margin: 0 0 20px;text-transform: uppercase;}
    .text{font-size: 18px;color: $black;padding: 0 0 30px;height: 4em;box-sizing: border-box;}
    .location{font-size: 22px;color: $black;border-top: 1px solid $black;padding-top: 20px;}
  }  
}