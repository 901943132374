@import "../../style/_variable.scss";
.section-title{
  font-size: 56px;font-weight: bold;color: $black;margin-bottom: 50px;
  position: relative;
  &:after{
    content: '';
    width: 80px;
    height: 10px;
    background: $black;
    position: absolute;
    bottom: -20px;
    left: 0px;
  }
}