@import "../../style/_variable.scss";
.portfolio_btn{
  background: #3198C9;
  color: #fff;
  padding: 10px;
  position: fixed;
  bottom: 0px;
  right: 0px;
  box-shadow: 0 0 6px #666;
  transition: 0.3s;
  &:hover{
    background: $black;
    color: #fff;
  }
}

@media screen and (max-width: 767px) {
  .portfolio_btn{
    background: #3198C9;
    color: #fff;
    padding: 10px;
    position: fixed;
    bottom: 0px;
    right: 0px;
    box-shadow: 0 0 6px #666;
    transition: 0.3s;
    &:hover{
      background: $black;
      color: #fff;
    }
  }  
}