@import "../../style/_variable.scss";
body{
  overflow-x: hidden;  
}
.section-mainv{
  background: url(../../images/kv.png) no-repeat center center;background-size: cover;
  width: 100%;
  height: 886px;
  h1{font-size: 75px;color: $black;font-weight: bold;line-height: 1;text-transform: uppercase;line-height: 0.9;margin: 120px 90px 30px;;}
  .lead{font-size: 38px;margin-left: 90px;text-transform: uppercase;
    &:after{
      content: '';
      background: url(../../images/shape-lb.png);
      background-size: 35px 36px;
      width: 35px;
      height: 36px;
      display: block;
      margin:-20px 0 0 -20px;
    }
  }
}
@media screen and (max-width: 767px) {
   .section-mainv{
      background: url(../../images/kv.png) no-repeat center center;background-size: cover;
      width: 100%;
      height: 886px;
      h1{font-size: 75px;color: $black;font-weight: bold;line-height: 1;text-transform: uppercase;line-height: 0.9;margin: 30px 0px 20px 20px;}
      .lead{font-size: 19px;margin-left: 30px;text-transform: uppercase;
        &:after{
          content: '';
          background: url(../../images/shape-lb.png);
          background-size: 35px 36px;
          width: 35px;
          height: 36px;
          display: block;
          margin:-20px 0 0 -20px;
        }
      }
    }
  }