@charset "utf-8";

.openbtn{
	position: absolute;
	background:#57a2c7;
	cursor: pointer;
  width: 50px;
  height:50px;
	border-radius: 5px;
	right: 10px;
	top:10px;
}

/*ボタン内側*/
.openbtn span{
    display: inline-block;
    transition: all .4s;/*アニメーションの設定*/
    position: absolute;
    left: 14px;
    height: 3px;
    border-radius: 2px;
	background: #fff;
  	width: 45%;
  }

.openbtn span:nth-of-type(1) {
	top:15px;	
}

.openbtn span:nth-of-type(2) {
	top:23px;
}

.openbtn span:nth-of-type(3) {
	top:31px;
}

/*activeクラスが付与されると線が回転して×に*/

.openbtn.active span:nth-of-type(1) {
    top: 18px;
    left: 18px;
    transform: translateY(6px) rotate(-45deg);
    width: 30%;
}

.openbtn.active span:nth-of-type(2) {
	opacity: 0;/*真ん中の線は透過*/
}

.openbtn.active span:nth-of-type(3){
    top: 30px;
    left: 18px;
    transform: translateY(-6px) rotate(45deg);
    width: 30%;
}

.box-menu{
  width: 000px;height: 500px;background: rgba(20, 96, 248, 0.3);
  position: absolute;top:90px;right: 0px;
  box-sizing: border-box;
  padding: 30px;
  line-height: 2;
  font-size: 20px;
  opacity: 0;
  transition: 0.5s;
  border-radius: 10px;
  ul{overflow: hidden;}
  &.active{
    opacity: 1;
    width: 300px;
  }
}
@media screen and (max-width: 767px) {
.box-menu{
  width: 000px;height: 500px;background: rgba(20, 96, 248, 0.6);
  color: #fff;
  position: absolute;top:90px;right: 0px;
  box-sizing: border-box;
  padding: 30px;
  line-height: 2;
  font-size: 20px;
  opacity: 0;
  transition: 0.5s;
  border-radius: 10px;
  &.active{
    opacity: 1;
    width: 300px;
  }
}

}