@import "../../style/_variable.scss";
#section-campaign{
  overflow: hidden;
  h2{
    font-size: 56px;
    color: $green;
    font-weight: bold;
    margin: 40px 0 0 50px;
    position: relative;
    left: 60px;
    &:after{
      content: '';
      background: url(../../images/shape-lb.png) no-repeat;
      background-size: 35px 35px;
      position: absolute;
      left: -20px;bottom: -20px;
      width: 35px;
      height: 35px;
    }
    &:before{
      content: '';
      background: url(../../images/shape-tr.png) no-repeat;
      background-size: 35px 35px;
      position: relative;
      right: -400px;
      top: 20px;
      width: 35px;
      height: 35px;
      display: block;      
    }
  }
  }
.box-wrapper{
  width: 1200px;
  margin: 50px auto;
  display: flex;
}

@media screen and (max-width: 767px) {
  #section-campaign{
    overflow: hidden;
    h2{
      font-size: 28px;
      color: $green;
      font-weight: bold;
      margin: 10px 0 0 20px;
      position: relative;
      left: 20px;
      width: auto;
      &:after{
        content: '';
        background: url(../../images/shape-lb.png) no-repeat;
        background-size: 35px 35px;
        position: absolute;
        left: -20px;bottom: -20px;
        width: 35px;
        height: 35px;
      }
      &:before{
        content: '';
        background: url(../../images/shape-tr.png) no-repeat;
        background-size: 35px 35px;
        position: relative;
        right: -200px;
        top: 20px;
        width: 35px;
        height: 35px;
        display: block;      
      }
    }    
    .box-wrapper{
      display: flex;
      width: 100%;
      padding: 0 2%;
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;
    }
  }
}