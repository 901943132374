@import "../../style/_variable.scss";
.box-class{
  .image{color: $green;
    text-align: center;
    .img{margin-bottom: 30px;}
    h3{font-size: 32px;font-weight: bold;margin-bottom: 20px;text-transform: uppercase;}
    .text{font-size: 20px;line-height: 1.6;}
    .btn{width: 200px;margin: 30px auto;;}
  }
}

@media screen and (max-width: 767px) {
  .box-class{
    margin: 0 0 0 20px;
    .image{color: $green;
      text-align: center;
      .img{margin-bottom: 30px;}
      h3{font-size: 32px;font-weight: bold;margin-bottom: 20px;text-transform: uppercase;}
      .text{font-size: 20px;line-height: 1.6;}
      .btn{width: 200px;margin: 30px auto;;}
    }
  }
}