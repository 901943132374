@import "../../style/_variable.scss";

.section-book-climbing{
  width: 100%;
  background: url(../../images/bg-mountain.png) no-repeat;
  background-position: center bottom;
  height: 1000px;
  padding-top: 0;
  padding-bottom: 100px;
  background-size: 100% auto;
  position: relative;
  overflow: hidden;
  h2{
    color: $black;
    font-size: 62px;
    font-weight: bold;
    position: relative;
    top:30px;
    left: 40px;
    &:after{
      content: '';
      background: url(../../images/shape-lb.png) no-repeat;
      background-size: 35px 35px;
      position: absolute;
      left: -20px;bottom: -20px;
      width: 35px;
      height: 35px;
    }
    &:before{
      content: '';
      background: url(../../images/shape-tr.png) no-repeat;
      background-size: 35px 35px;
      position: relative;
      right: -150px;
      top: 20px;
      width: 35px;
      height: 35px;
      display: block;      
    }
  }
  h3{
    font-size: 36px;color: $black;
    margin: 80px 0 20px 50px;
  }
  .lead{
    font-size: 24px;
    color: $black;
    width: 400px;
    margin: 0 0 0 50px;
    padding-bottom: 20px;
    line-height: 1.4;
    border-bottom: 2px solid $black;
  } 
 .bottom{
  margin: 30px 0 0 50px;
  color: $black;
  font-size: 24px;
  line-height: 1.4;;
  }
  .box-price{
    position: absolute;
    top:40px;
    right:50px;
    width: 300px;
    &::after{
      content: '';
      display: block;
      background: url(../../images/shape-lb.png) no-repeat;
      background-size: 35px 35px;
      width: 35px;
      height: 35px;
      position: absolute;
      left: -20px;
      bottom: -20px;      
    }
  
    .date{
      font-size: 24px;
      color: $black;
      .hs{
        font-size: 20px;
      }
    }
    .price{position: absolute;
      right: 80px;
      font-size: 24px;
      top:-10px
    }
  }
}

@media screen and (max-width: 767px) {
  .section-book-climbing{
    overflow: hidden;
    width: 100%;
    background: url(../../images/bg-mountain.png) no-repeat;
    background-position: center bottom;
    height: 800px;
    padding-top: 0;
    padding-bottom: 100px;
    background-size: 100% auto;
    position: relative;
    h2{
      color: $black;
      font-size: 31px;
      font-weight: bold;
      position: relative;
      top:30px;
      left: 40px;
      width: auto;
      &:after{
        content: '';
        background: url(../../images/shape-lb.png) no-repeat;
        background-size: 35px 35px;
        position: absolute;
        left: -20px;bottom: -20px;
        width: 35px;
        height: 35px;
      }
      &:before{
        content: '';
        background: url(../../images/shape-tr.png) no-repeat;
        background-size: 35px 35px;
        position: relative;
        right: -150px;
        top: 20px;
        width: 35px;
        height: 35px;
        display: block;      
      }
    }
    h3{
      font-size: 32px;color: $black;
      margin: 80px 0 20px 20px;
    }
    .lead{
      font-size: 24px;
      color: $black;      
      margin: 0 20px 0 20px;
      padding-bottom: 20px;
      line-height: 1.4;
      border-bottom: 2px solid $black;
    } 
   .bottom{
    margin: 30px 0 0 20px;
    color: $black;
    font-size: 24px;
    line-height: 1.4;;
    }
    .box-price{
      position: relative;
      top:40px;
      left:20px;
      &::after{
        content: '';
        display: block;
        background: url(../../images/shape-lb.png) no-repeat;
        background-size: 35px 35px;
        width: 35px;
        height: 35px;
        position: absolute;
        left: -20px;
        bottom: -20px;      
      }
    
      .date{
        font-size: 24px;
        color: $black;
        .hs{
          font-size: 20px;
        }
      }
      .price{position: absolute;
        right: 60px;
        font-size: 24px;
        top:0px
      }
    }
  }
}